<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <section class="auth">
    <div class="auth__container">
      <div class="auth__logo">
        <FullLogoIcon />
      </div>
      <form class="auth__form" autocomplete="off" spellcheck="false" ref="form" @submit.prevent="login">
        <h1 class="auth__title">Вход</h1>
        <InputComponent
          title="Электронная почта"
          type="email"
          :error="($v.loginForm.email.value.$error && submitStatus === 'ERROR') || submitStatus === 'INVALID'"
          v-model.trim.lazy="$v.loginForm.email.value.$model"
        />
        <InputComponent
          title="Введите пароль"
          :type="!showPassword ? 'password' : 'text'"
          :error="$v.loginForm.password.value.$error && submitStatus === 'ERROR'"
          v-model.trim.lazy="$v.loginForm.password.value.$model"
          @submit="showPassword = !showPassword"
        >
          <template v-slot:action="">
            <EyeOffIcon v-if="!showPassword" />
            <EyeOnIcon v-else />
          </template>
        </InputComponent>
        <router-link :to="{ name: 'RestorePassword' }" class="auth__forgot"> Забыли пароль?</router-link>
        <button type="submit" class="btn" :class="{ 'btn--loading': submitStatus === 'PENDING' }">
          <template v-if="submitStatus !== 'PENDING'">Войти</template>
        </button>
      </form>
      <div class="auth__switch">
        <span>У вас ещё нет аккаунта?</span>
        <router-link :to="{ name: 'Registration' }"> Зарегистрироваться</router-link>
      </div>
    </div>
  </section>
</template>

<script>
import FullLogoIcon from "@/components/svg/FullLogoIcon";
import InputComponent from "@/components/inputs/InputComponent";
import EyeOnIcon from "@/components/svg/EyeOnIcon";
import EyeOffIcon from "@/components/svg/EyeOffIcon";
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
  name: "LoginPage",
  data() {
    return {
      showPassword: false,
      loginForm: {
        email: {
          message: null,
          value: null,
        },
        password: {
          message: null,
          value: null,
        },
      },
      submitStatus: null,
    };
  },
  validations: {
    loginForm: {
      email: {
        value: {
          required,
          email,
          minLength: minLength(5),
        },
      },
      password: {
        value: {
          required,
          minLength: minLength(8),
        },
      },
    },
  },
  methods: {
    async login() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        this.$store
          .dispatch("LOGIN_USER", {
            context: this,
            variables: {
              email: this.loginForm.email.value,
              password: this.loginForm.password.value,
              user_agent: navigator.userAgent,
            },
          })
          .then(({ data }) => {
            if (data.LoginUser.code === 200) {
              this.$store.dispatch("SET_AUTH_TOKEN", data.LoginUser.user.token);
              this.$router.push({ name: "Requests" });
            } else {
              this.submitStatus = "ERROR";
              this.$notify({
                title: "Ошибка",
                text: data.LoginUser.message,
                type: "error",
              });
            }
          })
          .catch(() => {
            this.submitStatus = "INVALID";
            this.$notify({
              title: "Ошибка",
              text: "Неверный логин или пароль",
              type: "error",
            });
          });
      }
    },
  },
  components: { EyeOffIcon, EyeOnIcon, InputComponent, FullLogoIcon },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/auth"
</style>
